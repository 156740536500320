<template>
  <div class="signin" v-if="handoutType == 2">
    <van-nav-bar fixed >
      <template #left >
        <div style="padding-left: 1rem;font-weight: 600;">
          <van-tag color="#ffffff" text-color="#333333" size="medium" @click="onFontSize(-1)">
            <van-icon name="minus" color="#333333" style="font-weight: 600;" />
          </van-tag>
          <van-tag color="#ffffff" text-color="#333333" size="medium" @click="onFontSize(0)">默认</van-tag>
          <van-tag color="#ffffff" text-color="#333333" size="medium" @click="onFontSize(1)">
            <van-icon name="plus" color="#333333" style="font-weight: 600;" />
          </van-tag>
        </div>

      </template>
      <template #right @click-right="userKefu">
        <!-- <van-tag plain color="#333333" text-color="#333333" size="medium">
          <van-icon name="down" color="#333333" style="font-weight: 600;" />
          下载
        </van-tag> -->
        <!--          <div  style="padding-right: 1rem;">-->
        <!--            <van-button icon="down" type="default" size="mini" @click="onDownload">下载</van-button>-->
        <!--          </div>-->
      </template>
    </van-nav-bar>
    <div class="content1" id="content1">
      <div  :ref="item.play_type === true? 'activeItem' :''" :class="item.play_type === true ? 'handoutBox activeItem' : 'handoutBox'"  v-for="(item, index) in handoutList" :key="index">
        <van-cell class="content-top" @click="videoPlay2(index,item.start_time)">
          <template #title>
            <div v-html="item.content" class="custom-title-box" :style="{ fontSize: fontSize1 + 'rem',lineHeight:fontSize1*2 + 'rem' }"></div>
            <div class="custom-title-question">
              <!-- <van-cell class="comRadio_top" title="综合分析题" ></van-cell> -->
              <div class="comData_title" v-for="(item2,index2) in item.questions">
                <div>
                  <div class="stem">
                    <span class="data_value" v-html="item2.questions_titme"></span>
                    <div></div>
                  </div>
                  <div v-if="item2.stem == 2">
                    <div class="comRadio_option">
                      <div v-for="(item3,index3) in item2.data_content">
                        <div class="inputClass" v-if="item3.length > 0">
                          <span class="option_key">{{index3}}</span>
                          <span class="option_value"> {{item3}} </span>
                        </div>
                      </div>
                    </div>
                    <div >【正确答案】{{ item2.questions_answer }}</div>
                    <div class="questionAnalysis">【答案解析】{{item2.questions_analysis}}</div>
                  </div>
                  <div v-else>
                    <div v-for="(item3,index3) in item2.data">
                    <span style="margin-left: 0.2rem;">
                      <span class="typeTest">({{item3.cate_name}})</span>
                      <span v-html="item3.questions_titme"></span>
                    </span>
                      <div></div>
                      <span class="comData_scoreClass">（{{item3.test_score}}分）</span>
                      <div class="comRadio_option">
                        <div  v-for="(item4,index4) in item3.data_content" >
                          <div class="inputClass" v-if="item4.length > 0">
                            <span class="option_key">{{index4}}</span>
                            <span class="option_value"> {{item4}} </span>
                          </div>
                        </div>
                      </div>
                      <div >【正确答案】{{item3.questions_answer}}</div>
                      <div class="questionAnalysis">【答案解析】{{item3.questions_analysis}}</div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </template>
        </van-cell>
        <van-cell center class="bottom" v-if="item.play_type == true">
          <template #title>
            <div style="display: flex;">
              <van-button type="default" size="small" block @click="videoPlay(index,item.start_time)">
                <van-icon name="play-circle-o" color="#1989fa" style="padding-right: 0.5rem;" />播放/暂停
              </van-button>
              <van-button type="default" size="small" block @click="nodeAdd(item.id,item.start_time)">
                <van-icon name="edit" color="#1989fa" style="padding-right: 0.5rem;" />添加笔记
              </van-button>
            </div>
          </template>
        </van-cell>

      </div>

    </div>

  </div>
  <div v-else>
    <van-image
        width="100%"
        height="auto"
        lazy-load
        :src="handoutUrl"
    />
  </div>
</template>

<script>
import {getCoursewareHandout} from '@/request/api'

export default {
  beforeRouteEnter(to, from, next){
    next(vm => {
      // if(courseWareId) {
      //   vm.courseWareId = courseWareId;
      // }
    });
  },
  // beforeRouteUpdate(to, from, next){
  //   const courseWareId = to.query.courseWareId;
  //   window.confirm('beforeRouteUpdate:'+courseWareId);
  //   if(courseWareId) {
  //     // window.confirm('beforeRouteUpdate:'+courseWareId);
  //     this.courseWareId = courseWareId;
  //   }
  //   // alert('数据更新方法'+this.courseWareId);
  //   // this.coursewareHandout();
  //   next();
  // },
  data() {
    return {
      item: '',
      fontSize1: 2,
      checked: false,
      handoutItem: [
        {id:1,content:'<p style="text-indent: 24pt; text-align: left;"><strong>第一条 辅导内容及方式</strong></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">1.1 甲方经慎重考虑选择参加乙方组织的</span><u>2024年社会工作者网络辅导-不过重学班</u><span style="font-family: 宋体;">课程学习，课程包含：导学班+精讲班+强化班+模考班+点睛班+应收技巧班；</span></p><p style="text-indent: 24.1pt;"><strong>辅导科目、费用</strong></p><p style="text-indent: 24.1pt;"><br></p><table style="width: 100%;"><tbody><tr><td colspan="1" rowspan="1" width="148" style="text-align: center;">科目名称</td><td colSpan="1" rowSpan="1" width="148">班次</td><td colSpan="1" rowSpan="1" width="148">价位</td></tr><tr><td colspan="1" rowspan="1" width="148" style="text-align: center;">社会工作者-初级</td><td colSpan="1" rowSpan="1" width="148">不过重学班</td><td colSpan="1" rowSpan="1" width="148">*元/全科</td></tr><tr><td colspan="1" rowspan="1" width="148" style="text-align: center;">社会工作者-中级</td><td colSpan="1" rowSpan="1" width="148">不过重学班</td><td colSpan="1" rowSpan="1" width="148">*元/全科</td></tr></tbody></table><p style="text-indent: 24pt; text-align: left;"><strong>2024年未通过学员，免费重读2025年同班期网络课程。</strong></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">1.2 为确保辅导效果，在本协议约定的学习期间内，乙方可适当调整或延长课时，如延长辅导时间，乙方不另外收取费用。</span></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">1.3 甲方未经过乙方同意，个人原因放弃考试的，视为甲方通过考试，不享受第二年免费重读服务。</span></p><p><br></p>'},
        {id:2,content:'<p style="text-indent: 24pt; text-align: left;"><strong>第六条 </strong><span style="font-family: 宋体;">争议的解决</span></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">6.1 本协议的内容、执行和解释及争议的解决均应适用中国法律。</span></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">6.2 双方在履行本协议的过程中，如发生任何争议，则双方应首先进行友好协商解决。</span></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">6.3 如果某一争议未在一方首次提出协商之日后 30 日内通过友好协商解决，则任何一方可将该争议提交北京仲裁委员会申请仲裁。</span></p><p style="text-indent: 24pt; text-align: left;"><strong>第七条 附则</strong></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">7.1 本协议一式二份，甲乙双方各执一份；如有未尽事宜，由双方协商作出补充协议。</span></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">7.2 本协议自甲乙双方签字、盖章之日起生效；</span></p><p style="text-indent: 24pt; text-align: left;"><span style="font-family: 宋体;">7.3合同经双方协商一致可解除。 </span></p>'}
      ],
      courseWareId: '',
      handoutId: '',
      handoutList: [],
      timeOut:null,
      queryData: [],
      handoutType: 1,
      handoutUrl:''
    };

  },
  mounted() {
    const _that = this;
    // 监听事件总线上的事件
    this.$EventBus.$on('callYourMethod', (res)=>{
      _that.handoutUpdate(res)
    })
  },
  beforeDestroy() {
    const _that = this;
    // 组件销毁前取消监听，避免内存泄露
    this.$EventBus.$off('callYourMethod', (res)=>{
      _that.handoutUpdate(res)
    })
  },
  watch: {
    // 监听courseWareId的值变化
    // courseWareId: function (newVal, oldVal) {
    //   // console.log(newVal,oldVal);
    //   window.confirm('新：'+newVal+'原来：'+oldVal+'参数：'+this.courseWareId);
    //   if (newVal) {
    //     this.coursewareHandout();
    //   }
    // },

    // '$route.query': {
    //   immediate: true,
    //   courseWareId(newQuery, oldQuery) {
    //     // 当路由查询参数发生变化时，这里会被调用
    //     console.log('Query changed:', newQuery, oldQuery);
    //   }
    // },

    '$route.query.courseWareId': {
      immediate: true,
      handler(newQuery, oldQuery) {
        // 当路由查询参数发生变化时，这里会被调用
        this.courseWareId = newQuery;
        this.coursewareHandout();
        // ceshi({courseWareId:newQuery});
        console.log('Query changed:', newQuery, oldQuery);
      }
    },

    // '$route'(to, from) {
    //   // 更新参数
    //   this.courseWareId = to.query.courseWareId;
    //   const queryData = to.query
    //   queryData.ceshi = 'watch';
    //   this.queryData = queryData
    //   window.confirm('参数：'+this.courseWareId);
    //   // 根据参数更新页面内容或执行操作
    //   this.coursewareHandout();
    //   ceshi({queryData:queryData});
    // },
  },

  methods: {
    onBack() {
      history.back();
    },
    userKefu(){
      this.$router.push({ path: '/user' });
    },
    onFontSize(index) {
      if (index == -1) {
        if (this.fontSize1 > 1.5) {
          this.fontSize1 = this.fontSize1 - 0.4
        }
      } else if (index == 0) {
        this.fontSize1 = 2
      } else {
        if (this.fontSize1 < 3) {
          this.fontSize1 = this.fontSize1 + 0.4
          console.log(this.fontSize1)
        }

      }
    },
    coursewareHandout() {
      let that = this;
      let courseWareId = that.courseWareId;
      let queryData = that.queryData;
      getCoursewareHandout({courseWareId,queryData:queryData})
          .then(res => {
            if (res.data.code == 1) {
              this.handoutType = res.data.data.type;
              this.handoutUrl = res.data.data.url;
              this.handoutList = res.data.data.list;
            } else {
              this.handoutList = [];
            }
            that.courseWareId='';
          })
          .catch(err => {
            console.log("error");
            console.log(err);
          })
    },
    videoPlay(key,startTime) {
      let handout_list = this.handoutList;
      if(handout_list[key].play_status == true) {
        handout_list[key].play_status = false;
        this.handoutList = handout_list;
        window.android.pause();
      } else {
        handout_list[key].play_status = true;
        this.handoutList = handout_list;
        window.android.jump(startTime)
      }
    },
    videoPlay2(key,startTime) {
      let handout_list = this.handoutList;
      if(handout_list[key].play_status == true) {
        return false;
      } else {
        handout_list[key].play_status = true;
      }
      this.handoutList = handout_list;
      window.android.jump(startTime)
    },
    nodeAdd(handoutId,startTime) {
      window.android.note()
    },
    async handoutUpdate(currentTime) {
      let that = this;
      var handout_list = that.handoutList;
      var handoutId = that.handoutId;
      let len = handout_list.length;
      // play_status 播放状态 播放/暂停
      // play_type 选中效果
      for (let i = 0; i < len; i++) {
        let j = i + 1;
        let info = handout_list[i];
        if (j >= len) {
          if (currentTime >= info.start_time) {
            //判断是否重复

            if(handoutId ==  handout_list[i].id) {
              return;
            }
            // if(playStatus){
            //  handout_list[i].play_status = true;
            // }else {
            //   handout_list[i].play_status = false;
            // }
            handout_list[i].play_status = true;
            handout_list[i].play_type = true;
            handoutId = handout_list[i].id;
            // const element = this.$refs.ref0
            // console.log(this.$refs);
            // console.log(i);
            // console.log('element',element);
            // if (element) {
            //   element.scrollIntoView({ behavior: 'smooth' });
            // }
            // this.selectedArticleIndex = i;

          } else {
            handout_list[i].play_type = false;
            handout_list[i].play_status = false;
          }
        } else {
          let info2 = handout_list[i + 1];
          if (currentTime >= info.start_time && currentTime < info2.start_time) {
            //判断是否重复

            if(handoutId == handout_list[i].id) {
              return;
            }
            // if(playStatus){
            //   handout_list[i].play_status = true;
            // }else {
            //   handout_list[i].play_status = false;
            // }
            handout_list[i].play_status = true;
            handout_list[i].play_type = true;
            handoutId = handout_list[i].id;
            // const element = this.$refs.ref0;
            // console.log(this.$refs);
            // console.log(i);
            // console.log('element',element);
            // if (element) {
            //   element.scrollIntoView({ behavior: 'smooth' });
            // }
            // this.selectedArticleIndex = i;
          } else {
            handout_list[i].play_status = false;
            handout_list[i].play_type = false;
          }
        }
      }



      this.handoutList = handout_list;
      this.handoutId = handoutId;
      await  this.$forceUpdate();
      // await this.getClass();
      let out = setTimeout(function () {
        that.getClass();
        clearTimeout(out);
      },1000)
    },
    getClass() {
      const elements = document.getElementsByClassName("activeItem");
      const element = elements[0];
      // console.log(elements);
      // console.log('class',element);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
  created() {
    const courseWareId = this.$route.query.courseWareId;
    // this.queryData = this.$route.query
    // const queryData = this.$route.query
    // queryData.ceshi = 'created';
    // this.queryData = queryData
    // window.confirm('created:'+courseWareId);
    // this.courseWareId = courseWareId;
    // this.coursewareHandout();
    // if(courseWareId != 0 && courseWareId!= null && courseWareId!= '') {

    // }
    // if (this.$route.params.courseWareId) {
    //   this.courseWareId = this.$route.params.courseWareId;//上次记录
    // } else {
    //
    // }
    // this.coursewareHandout();
  }

};

</script>
<style lang="less">
@import '~@/assets/styles/common.less';

.signin {
  // background-color: #ffffff;
  // 
  text-align: center;
  color: #666666;
  /* background-image: url('http://cdnoss.ksyt.com.cn/wxImages/2022yaoshi_yuekao.jpg'); */
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #f5f5f5;
}

.signin .van-nav-bar {
  // background: #1989fa;
  z-index: 999999;
}

.signin .van-hairline--bottom::after {
  border-bottom-width: 0;
}

.signin .van-nav-bar .van-icon {
  color: #ffffff;
  font-size: 2rem !important;
}

.signin .van-nav-bar__title {
  color: #ffffff;
}

.popup-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 99999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 20;
}

// .slide-enter, .slide-leave-to {
//   // 过渡之前的样式写在这里
//    right: -100%;
// }
// .slide-enter-active,.slide-leave-active  {
//   // 过渡属性写在这里
//   transition: all .3s;
// }
// .slide-enter-to, .slide-leave {
//   // 过渡之后的样式写在这里
//    right: 0;
// }s

.content1 {
  padding: 8rem 0.3rem 1.3rem 0.3rem;
}

.handoutBox {
  margin-bottom: 1rem
}

.content-top {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1rem;
}

.content-top .custom-title-box {
  text-align: left;
  display: block;
  color: #0d0d0d;
}

.custom-title-question{
  color: #0d0d0d;
}

.bottom {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  height: 6rem;
  padding: 1rem;
}

.bottom .van-cell__title .custom-title b {
  font-weight: 600;
  font-size: 2.3rem;
  padding: 0 0.8rem;
  color: #fb4d19;
}

strong {font-weight: bold;}
table {border: 0.1rem solid #333333;border-radius: 1rem;}
table tr,table td {border: 0.1rem solid #333333;}


.comRadio_top[data-v-a862932a] {
  height: 3rem !important;
  /* border-bottom: 0.1rem solid #f7f7f7; */
  position: fixed;
  left: 2rem;
  padding-right: 4rem;
  z-index: 99;
}

.comData_title {
  width: auto;
  text-align: left;
  padding: 1rem 0;
  font-size: 2.2rem;
  line-height: 3.2rem;
}
.comData_title .stem {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.typeTest {
  color: #4ba5ff;
}
.comData_scoreClass {
  color: #a0a0a0;
}
.comData_title .comRadio_option {
  margin: 1rem 0rem;
}
.comData_title .inputClass {
  position: relative;
  height: auto;
  padding: 1rem;
  /* margin-left: 15px; */
  text-align: left;
  // margin-top: 1.5rem;
  background: #f6f7f9;
  border: 0.1rem solid #f6f7f9;
  border-radius: 0.5rem;
  font-size: 2rem;
  line-height: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.comData_title .inputClass .option_key {
  border: 0.1rem solid #cccccc;
  border-radius: 100%;
  height: 2rem;
  width: 2rem;
  padding: 0.4rem;
  position: absolute;
  left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* display: none; */
}
.comData_title .inputClass .option_value {
  padding-left: 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.activeItem {
  background-color:#ebf1fc;
  border:0.2rem solid #0052d9!important;
  border-radius: 1rem;
}
.activeItem .van-cell {
  background-color:#ebf1fc !important;

}
table, table tr, table td{
  border: 1px solid #000;
}
.questionAnalysis{
  color: #07c160;
}


</style>
