<!--
 * @Author: wangcheng 1637694386@qq.com
 * @Date: 2025-01-14 11:57:16
 * @LastEditors: wangcheng 1637694386@qq.com
 * @LastEditTime: 2025-01-15 09:45:58
 * @FilePath: \sgMobileOnlineSchool\src\views\appDownload.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="appBox">
    <van-nav-bar class="cart1-title" fixed >
      <template #title>
        百年社工APP下载
      </template>
      <template #right>
            
      <van-popover
          v-model="showPopover"
          placement="bottom-end"
          theme="dark"
          :close-on-click-outside="false"
          :actions="actions"
          @select="onSelect"
        >
          <template #reference>
            <van-icon name="search" class="searchIcon" size="0"></van-icon>
          </template>
      </van-popover>
      </template>

    </van-nav-bar>

    
    <div style="padding: 6rem 0 22rem 0;background-color: #ffffff;">

          <div class="contentApp">
            <van-image
              width="220"
              height="220"
              src="https://cdnoss.ksyt.com.cn/app/000/bainianshegong.png"
            />
            <div class="tags">长按识别二维码（<span style="color: #ff5520;">手机浏览器中）</span></div>
            <van-button round size="large" type="info" @click="downloadAndr">Android下载</van-button>
            <van-button round size="large" type="warning">微信小程序</van-button>
          </div>
          <div>
          </div>
    </div>
  </div>
</template>

<script>

  import {Toast} from "vant";

  export default {
      name: 'AppDownload',
      metaInfo: {
          title: '百年社工APP', //设置页面title
          meta: [{                 //设置页面meta
              name: 'keyWords',
              content: '百年社工APP下载'
          },
              {
                  name: "description",
                  content: "百年社工APP下载",
              },
          ]
      },
      data() {
          return {
              // active:'',
              showPopover: false,
              // 通过 actions 属性来定义菜单选项
              actions: [{ text: '点击右上角选择' }, { text: '在浏览器打开' }],
          }
      },

      methods: {

          onSelect(action) {
            Toast(action.text);
          },

          onBack() {
              this.$router.push('/learningList')
          },
          downloadAndr(){
            console.log(3);
            if(this.showPopover){
              return false;
            }else{
              window.location.href = 'https://cdnoss.ksyt.com.cn/app/000/bainianshegong-1.0.0.apk';
            }
          },

          
      },
      created() {
        var ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
          if (ua.match(/MicroMessenger/i) == 'micromessenger' ) {
            // cosole.log('这里是微信浏览器')
            // Toast.fail('这里是微信浏览器')
            this.showPopover = true
          } else {
            // window.location.replace("about:blank"); //地址栏
            // window.close();  //关闭
            // return false;
            console.log('浏览器')
          }
      }
  }
</script>

<style lang="less">
.van-popover__action {
  width: auto !important;
  font-size: 2rem !important;
  padding: 1rem !important;
  height: 6rem !important;
}
.searchIcon {
  position: fixed;
  top: -10px;
  right: 0;
  z-index: 999;
}
.cart1-title {
  border-bottom: .1rem solid #f2f2f2;
}
.contentApp {
  padding: 2rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contentApp .van-image {
  margin-bottom: 1rem;
  padding: 1rem;
  border: .2rem solid #f2f2f2;
}
.contentApp .tags {
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
.contentApp .van-button {
  margin-top: 2rem;
  width: 20rem;
}

</style>